import React, { useContext, useState } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import SelectUi from "../ui/Select/SelectUi";
import SelectListUi from "../ui/SelectList/SelectListUi";

export default function Step1Component() {
  const [currentSelect, setCurrentSelect] = useState(1);

  const context = useContext(ConfiguratorContext);
  const onClickTipologiaBasi = (tipologia) => {
    context.setData({
      typologyBasi: tipologia.id,
    });
    setCurrentSelect(1);
  };

  return (
    <>
      <p className="SidebarStepsUi__label">
        {window.jsLocale("configurator_s1_title")}
      </p>
      <div className="SidebarStepsUi__container">
        <SelectUi
          label={window.jsLocale("configurator_base")}
          selected={context.selectedBase?.label}
          open={currentSelect == 1}
          setOpen={(open) =>
            setCurrentSelect(open ? 1 : currentSelect == 1 ? 0 : currentSelect)
          }
        ></SelectUi>
        {context.configuration.data.base && (
          <SelectUi
            label={window.jsLocale("index_filters_typology")}
            selected={
              context.selectedTypologyBasi?.label
                ? context.selectedTypologyBasi?.label
                : window.jsLocale("index_filters_typology_all_materials")
            }
            open={currentSelect == 2}
            setOpen={(open) =>
              setCurrentSelect(
                open ? 2 : currentSelect == 2 ? 0 : currentSelect
              )
            }
          >
            <SelectListUi
              options={context.state.productData.basi_tipologie}
              activeId={context.configuration.data.typologyBasi}
              onClick={onClickTipologiaBasi}
            />
          </SelectUi>
        )}
      </div>
      {(currentSelect == 1 || currentSelect == 2) && (
        <ul className="SidebarStepsUi__basi">
          {context.basi.map((product) => (
            <li
              key={product.id}
              className={[
                "SidebarStepsUi__base",
                context.configuration.data.base == product.id
                  ? "is-active"
                  : "",
              ].join(" ")}
              onClick={(e) => {
                context.setData({
                  base: product.id,
                  textureBase: null,
                  traslazioneY: product.gltf_translation_y,
                  traslazioneZ: product.gltf_translation_z,
                  rotazioneX: product.gltf_rotation_x,
                });
                console.log('ciao')
                console.log(product)
              }}
            >
              {product.swivel && (
                <img src={"/images/360.jpg"} class="swivel"></img>
              )}
              <img src={product.image || "/images/placeholder.jpg"}></img>

              <div className="SidebarStepsUi__base-title">
                <div className="selector"></div> {product.label}
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
